import { Heading1, Heading2, Paragraph } from '../components/Typography';
import React, { useCallback, useMemo } from 'react';

import Button from '../components/Button';
import Flexbox from '../components/Flexbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '../components/Grid';
import HeroSection from '../components/HeroSection';
import Media from '../components/Media';
import PropTypes from 'prop-types';
import Section from '../components/Section';
import accountFeatures from '../images/account-features-image.png';
import checkBalance from '../images/check-balance.svg';
import defaultTheme from '../utils/themes';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import heroImage from '../images/hero-image.png';
import makePayments from '../images/make-payments.svg';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { typography } from 'styled-system';
import uploadDocument from '../images/upload-document.svg';

const BulletText = styled.p`
  margin-left: 16px;
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 0;
`;

const Checkpoint = ({ content }) => (
  <div
    css={`
      display: flex;
      margin-bottom: 16px;
      align-items: center;
    `}
  >
    <FontAwesomeIcon icon={faCheck} color='#494bb5' size='xs' />
    <BulletText fontSize='0.9rem'>{content}</BulletText>
  </div>
);

Checkpoint.propTypes = {
  content: PropTypes.string.isRequired,
};

const TakeControlBullets = () => (
  <div>
    <Heading2 fontSize='1.6rem' marginBottom='1.2rem'>
      Take control of your account
    </Heading2>

    <div
      css={`
        margin-left: 1rem;
      `}
    >
      <Checkpoint content='View arrangement details' />
      <Checkpoint content='Export statements as PDFs' />
      <Checkpoint content='View & request amendments to your details' />
      <Checkpoint content='View council details' />
      <Checkpoint content='View your current balance' />
    </div>
  </div>
);

const FeatureContainer = styled.div(typography);

const Feature = ({ heading, iconSrc, content }) => (
  <FeatureContainer
    textAlign={useMemo(
      () => ({
        xs: 'center',
        lg: 'left',
      }),
      []
    )}
  >
    <Media src={iconSrc} />
    <Heading2 fontSize='1rem'>{heading}</Heading2>
    <Paragraph fontSize='0.9rem' lineHeight='1.3'>
      {content}
    </Paragraph>
  </FeatureContainer>
);

Feature.propTypes = {
  heading: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const HeroSectionContentWrapper = styled.div``;

const JacobsAccountPage = () => (
  <>
    <HeroSection
      ContentWrapperComponent={HeroSectionContentWrapper}
      paddingTop='8px'
    >
      <Grid
        alignItems='center'
        gridTemplateRows={useMemo(
          () => ({
            xs: 'repeat(2, auto)',
            lg: 'auto',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            lg: 'repeat(2, 1fr)',
          }),
          []
        )}
      >
        <div>
          <Heading1 marginTop='120px'>Introducing Jacobs Customer</Heading1>

          <Paragraph>
            Manage your account online. Make payments, check statements, upload
            documents, and more, from your desktop or mobile.
          </Paragraph>
        </div>
        <Media marginTop='120px' width='100%' src={heroImage} />
      </Grid>
    </HeroSection>

    <Section backgroundColor='grey' paddingTop='60px' paddingBottom='60px'>
      <Heading2 fontSize='1.5rem;' marginBottom='2.5rem' textAlign='center'>
        Features
      </Heading2>

      <Grid
        ieCardLayout
        gridTemplateRows={useMemo(
          () => ({
            xs: 'repeat(3, auto)',
            lg: 'auto',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            lg: 'repeat(3, 1fr)',
          }),
          []
        )}
        gridColumnGap='1.5rem'
        gridRowGap='0.75rem'
      >
        <Feature
          iconSrc={checkBalance}
          heading='Check statements'
          content='Check your current remaining balance.'
        />
        <Feature
          iconSrc={uploadDocument}
          heading='Upload documents'
          content='Easily upload and submit documentation securely.'
        />
        <Feature
          iconSrc={makePayments}
          heading='Make payments'
          content='Make payments safely and securely directly from your account.'
        />
      </Grid>
    </Section>

    <Section backgroundColor='white' marginTop='110px' marginBottom='120px'>
      <Grid
        alignItems='center'
        justifyItems='stretch'
        gridTemplateRows={useMemo(
          () => ({
            xs: 'repeat(2, auto)',
            lg: 'auto',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            lg: '3fr 2fr',
          }),
          []
        )}
        gridRowGap='40px'
        gridColumnGap='80px'
      >
        <Media src={accountFeatures} width='100%' />
        <TakeControlBullets />
      </Grid>
    </Section>

    <Section backgroundColor='grey' paddingTop='60px' paddingBottom='60px'>
      <Flexbox
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
      >
        <Heading2
          fontSize='1.6rem'
          textAlign='center'
          maxWidth='410px'
          lineHeight='1.3'
          marginBottom='2rem'
        >
          Find out how Jacobs Customer can help you
        </Heading2>

        <Button
          backgroundColor='secondary'
          height='48px'
          onClick={useCallback(() => navigate('/login'), [])}
          width='240px'
        >
          Get started now
        </Button>
      </Flexbox>
    </Section>
  </>
);

JacobsAccountPage.propTypes = {
  theme: PropTypes.object,
};

JacobsAccountPage.defaultProps = {
  theme: defaultTheme,
};

export default JacobsAccountPage;
